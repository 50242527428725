import React, { useEffect, useState, useRef } from "react";
import { Button } from "reactstrap";
import '../../../../scss/component/Charts.scss';
import { useForm, Controller } from 'react-hook-form';
import { Timeline } from 'primereact/timeline';

import dateFormats from '../../../UI/FormatDate/formatDate';
import fetchMethodRequest from "../../../../config/service";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import Loader from "../../../App/Loader"
import { Dialog } from 'primereact/dialog';
import DataTable from "../../CommonDataTable/DataTable";
import DoSelectField from "../../../Form/Fields/DoSelectField";
import DoAutoCompleteField from "../../../Form/Fields/DoAutoCompleteField";

const Charts = (props) => {
  const { children } = props;
  let { handleSubmit, register, reset, setValue, getValues, formState: { errors }, control, watch } = useForm();
  const [procedureCodes, setProcedureCodes] = useState([]);
  const [selectedProcedureCodes, setSelectedProcedureCodes] = useState([]);
  const [teethsData, setTeethsData] = useState();
  const [defaultTeethsData, setDefaultTeethsData] = useState();
  const [selectedTeeths, setSelectedTeeths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [teethHistorys, setTeethHistorys] = useState();
  const [selectedTeethHistory, setSelecetedTeethHistory] = useState(-1);
  const [addProcedureType, setAddProceduresType] = useState();
  const debouncedSaveChartHistory = Debounce(saveChartHistory, 1000);//To Avoid the over iterating
  const dataTableRef = useRef(null);
  const teethWidth = {
    1: '42px', 2: '45px', 3: '45px', 4: '32px', 5: '32px', 6: '34px', 7: '30px', 8: '40px', 9: '40px', 10: '30px', 11: '34px', 12: '32px', 13: '32px', 14: '45px', 15: '45px', 16: '42px',
    17: '42px', 18: '45px', 19: '45px', 20: '32px', 21: '32px', 22: '34px', 23: '30px', 24: '40px', 25: '40px', 26: '30px', 27: '34px', 28: '32px', 29: '32px', 30: '45px', 31: '45px', 32: '42px',
  }

  //Table column fields for the add Procedures codes
  const getTableFields = () => {
    const tableFields = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: addProcedureType == 'procedureCodes',
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "code",
        type: "uniqueField",
        placeholder: "Code",
        label: "Code",
        width: "130px",
        header: "Code",
        capitalizeTableText: true,
        filter: true,
        id: "code",
        displayinlist: "true",
        globalSearchField: "true",
        textAlign: "Center",
        show: true,
        field: "code",
      },
      {
        name: "description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        header: "Description",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "description",
        textAlign: "left",
        show: true,
        field: "description",
        filter: true,
        style: {
          maxWidth: '400px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        },
      },
      {
        name: "fee",
        type: "text",
        placeholder: "Fee",
        label: "Fee",
        width: "130px",
        header: "Fee",
        id: "fee",
        textAlign: "Center",
        show: true,
        field: "displayFee",
      },
      {
        name: "category",
        type: "dropDown",
        placeholder: "Category",
        label: "Category",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Category",
        filter: true,
        id: "category",
        textAlign: "Center",
        show: true,
        field: "category",
        fieldType: "dropDown",
        filterType:"dropdown",
        filterOptions: [
          {label: "Diagnostic",value: "Diagnostic",},
          { label: "Preventive" ,value:"Preventive"},
          { label: "Restorative" ,value:"Restorative"},
          { label: "Endodontics" ,value:"Endodontics"},
          { label: "Periodontics" ,value:"Periodontics"},
          { label: "Prosthodontics Removable" ,value:"Prosthodontics Removable"},
          { label: "Implant Services" ,value:"Implant Services"},
          { label: "Prosthodontics Fixed" ,value:"Prosthodontics Fixed"},
          { label: "Oral and Maxillofacial Surgery" ,value:"Oral and Maxillofacial Surgery"},
          { label: "Orthodontics" ,value:"Orthodontics"},
          { label: "Adjunctive Services" ,value:"Adjunctive Services"},
        ],
      },
    ];

    return tableFields;
  }



  useEffect(() => {
    setIsLoading(true);
    getProcedureCodes();
    getAllDefaultTeethsData();

  }, []);

  useEffect(() => {
    setIsLoading(true);
    displayTeethBasedOnTP(defaultTeethsData, props.dataTableData);
  }, [props.dataTableData]);

  useEffect(() => { getTeethHistorys(); setSelecetedTeethHistory(-1); }, [props.watch('treatmentPlanHeading.chartHistoryId')]);

  /**
   * 
   * @param {Function} func 
   * @param {Number} delay 
   * @returns 
   */
  function Debounce(func, delay) {//To Avoid the over iterating
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  //It will get the history of the Treatment plan
  const getTeethHistorys = async () => {
    const chartHistoryId = await props.getValues('treatmentPlanHeading.chartHistoryId');
    if (chartHistoryId) {
      const api = apiCalls.chartHistories + `/${chartHistoryId}`;
      fetchMethodRequest("GET", api).then((res) => {
        let historys = res?.details?.teethHistoryImages;
        setTeethHistorys(historys?.length > 0 ? historys : []);
        // if (historys?.length === 0) {
        //   setTimeout(() => {
        //     debouncedSaveChartHistory(teethsData);
        //   }, 300);
        // }
      });
    } else {
      setTeethHistorys([]);
    }
  }


  //It get the all Procedures
  const getProcedureCodes = () => {
    fetchMethodRequest('GET', `${apiCalls.procedures}?type=exportToCsv`).then(res => {
      const procedureCodes = res[apiCalls.procedures] && res[apiCalls.procedures].length > 0 ? res[apiCalls.procedures] : [];
      setProcedureCodes(procedureCodes);
    });
  }

  /**
   * 
   * @param {Array} teethsData 
   * @param {Array} dataTableData 
   */
  //It display the Teeth based on the Procedure codes applyed to the Teeth
  const displayTeethBasedOnTP = (teethsData, dataTableData) => {

    if (teethsData && dataTableData?.length) {
      let teethInfo = [];
      const hideTeeths = getHideTeeths();
      let arr = dataTableData.map((teeth) => {
        const teethExist = teethInfo.some(t => t.teethNumber == teeth.teethNumber);
        const topViewTeeth = addProcedureToTeeth(teethExist ? teethInfo : teethsData, teeth.teethNumber, teeth.code, 'T', teethExist);
        const frontViewTeeth = addProcedureToTeeth(teethExist ? teethInfo : teethsData, teeth.teethNumber, teeth.code, 'F', teethExist);
        if (topViewTeeth && frontViewTeeth) {
          topViewTeeth.show = hideTeeths[teeth.teethNumber] ? false : true;
          frontViewTeeth.show = hideTeeths[teeth.teethNumber] ? false : true;
          if (teethExist) {
            const topTeethIndex = teethInfo.findIndex(teeth => teeth._id == topViewTeeth._id);
            const frontTeethIndex = teethInfo.findIndex(teeth => teeth._id == frontViewTeeth._id);
            teethInfo[topTeethIndex] = topViewTeeth;
            teethInfo[frontTeethIndex] = frontViewTeeth;
          } else {
            teethInfo = [...teethInfo, topViewTeeth, frontViewTeeth];
          }
        }
      })
      teethInfo?.length >= 0 ? getProcedureImages({ teethInfo }, teethsData) : setIsLoading(false);
    }
  }

  /**
   * 
   * @param {Array} teeths 
   * @param {Number} teethNumber 
   * @param {String} procedureCode 
   * @param {String} viewType 
   * @returns Object
   */
  const addProcedureToTeeth = (teeths, teethNumber, code, viewType, teethExist) => {
    const teeth = teeths.find(teeth => teeth.teethNumber == teethNumber && teeth.viewType == viewType);
    return teeth ? getTeethData(teeth, [code], teethExist) : null;
  }

  //Get the default teeth without apply any Procedures
  function getAllDefaultTeethsData() {
    fetchMethodRequest("GET", apiCalls.teethImages).then(res => {
      const teethsData = res[apiCalls.teethImages]
      if (teethsData?.length) {
        setDefaultTeethsData(teethsData);
        setTeethsData(teethsData);
        displayTeethBasedOnTP(teethsData, props.dataTableData);
      }
    });
  }

  /**
   * 
   * @param {Object} teeth 
   * @returns String
   */
  //Path of the Teeth image
  const getImagePath = (teeth) => {
    const teethUrl = config.imgUrl + apiCalls.getTeethImages + '/' + teeth.imagePath;
    return teethUrl;
  };


  /**
   * 
   * @param {Object} teeth 
   */
  //Onclick on the Teeth
  async function onClickImage(teeth) {
    teeth.applyBoth = true;
    let sldTeeths = selectedTeeths;
    if (sldTeeths.some(obj => obj.teethNumber === teeth.teethNumber)) {
      sldTeeths = await sldTeeths.filter(obj => obj.teethNumber !== teeth.teethNumber)
    } else {
      sldTeeths = await [...sldTeeths, teeth]
    }
    setSelectedTeeths(sldTeeths);
  }

  /**
   * 
   * @returns Object
   */
  const getHideTeeths = (index) => {//Return the hideTeeth
    const hideTeeth = index >= 0 && teethHistorys[index]?.hideTeeth ? teethHistorys[index].hideTeeth : props.getValues('treatmentPlanHeading.hideTeeth') ? props.getValues('treatmentPlanHeading.hideTeeth') : {};
    return hideTeeth;
  }


  /**
   * 
   * @param {Array} arr 
   * @param {Boolean} displayNum 
   * @returns 
   */
  function displayTeeth(arr, hideTeeth, displayNum) {

    return <div className="d-flex">
      {arr.map((teeth, i) => (
        <div key={teeth.teethNumber} className={typeof (displayNum) == 'string' ? displayNum : ""} style={{ width: teethWidth[teeth.teethNumber] ? teethWidth[teeth.teethNumber] : "50px" }} onClick={() => onClickImage(teeth)}>
          {displayNum === true ? <div className="tooth_num"><b className={`${selectedTeeths.some(obj => obj.teethNumber == teeth.teethNumber) ? "selected" : ''}`}>{teeth.teethNumber}</b></div>
            : <div >{!hideTeeth[teeth.teethNumber] || (teeth?.procedureCodes?.includes('D6710') && !teeth.show) ? <img title={teeth.procedureCodes
              // ?.length > 0 ? teeth.procedureCodes : 'No Procedure'
            } src={getImagePath(teeth)} className="tooth_img" /> : ''}</div>
          }
        </div>
      ))}
    </div>
  }

  /**
   * 
   * @param {String} field 
   * @param {any} value 
   */
  const applyToSelecetedTeeth = async (type, value) => {//Applu the Procedure for the Teeths
    setIsLoading(true);
    const hideTeeth = getHideTeeths();
    const dataTableData = await props.dataTableData

    selectedTeeths.map(teeth => {
      if (type == 'show') {
        hideTeeth[teeth.teethNumber] = value;
      }
    });
    props.setValue('treatmentPlanHeading.hideTeeth', hideTeeth);
    displayTeethBasedOnTP(defaultTeethsData, dataTableData);
    props.setDataTableData(dataTableData, true);
  }

  /**
   * 
   * @param {Object} teeth 
   * @param {Array} selectedProcedures 
   * @returns Object
   */
  const getTeethData = (teeth, selectedProcedures, teethExist) => {
    let procedureCodes = teeth?.procedureCodes && teethExist ? teeth.procedureCodes : [];
    let obj = {};//{ D7110: 'EX', D6710: 'BD', D3920: 'RC', EX: 'EX', BD: 'BD', RC: 'RC' };
    selectedProcedures.map(code => {
      code = obj[code] ? obj[code] : code;
      if (!procedureCodes.includes(code)) {
        procedureCodes.push(code);
      }
    })
    selectedProcedures = obj[selectedProcedures] ? obj[selectedProcedures] : selectedProcedures;
    teeth.procedureCodes = procedureCodes;
    return teeth;
  }


  const onClickApplyTP = async (selectedProcedureCodes) => {//apply Procedure for the Teeths
    setSelecetedTeethHistory(-1);
    closeProceduresModal();
    if (selectedProcedureCodes.length > 0) {
      setIsLoading(true);
      let dataTableData = props.dataTableData ? props.dataTableData : [];

      selectedProcedureCodes = await selectedProcedureCodes.map(async procedure => {
        let pCode = await selectedTeeths.map(async selectedTeeth => {
          let exits = await !dataTableData.find(teeth => teeth.teethNumber && teeth.teethNumber == selectedTeeth.teethNumber && teeth.code == procedure.code);
          if (exits) {
            dataTableData.push({ ...procedure, teethNumber: selectedTeeth.teethNumber });
          }
        })
        return procedure;
      })

      displayTeethBasedOnTP(defaultTeethsData, dataTableData);
      props.setDataTableData(dataTableData, true);

      // if (dataTableRef.current) {//Remove the filter
      //   dataTableRef.current.reset();
      // }
    }
  }

  /**
   * 
   * @param {Object} data 
   * @param {Array} teethsData 
   */
  const getProcedureImages = (data, teethsData) => {
    fetchMethodRequest("POST", apiCalls.getImagesByProcedure, data).then(res => {
      const teetnArr = res[apiCalls.teethImages]
      if (teetnArr?.length >= 0) {
        const arr = teethsData.map(teeth => {
          const particularTeeth = teetnArr.find(o => o.teethNumber == teeth.teethNumber && o.viewType == teeth.viewType);
          if (particularTeeth) {
            particularTeeth.procedureCodes = teeth.procedureCodes;//from the server there there did not send the updated procedures
            return particularTeeth;
          }
          return teeth;
        });
        setTeethsData(arr);
        setSelectedProcedureCodes([]);
        setIsLoading(false);
        setSelectedTeeths([]);
        debouncedSaveChartHistory(arr);
      }
    })

  }


  const applyFillingToSelecetedTeeth = async (type, codes) => {
    let procedures = await procedureCodes.filter(procedure => codes.includes(procedure.code))
    onClickApplyTP(procedures);
  }

  /**
   * 
   * @param {Object} e 
   */
  //Apply the procedure codes for the teeths
  const onClickDataTableApplyTP = (e) => {
    dataTableRef?.current?.setSelectedRows((pCodes => {
      if (pCodes.length > 0) {
        onClickApplyTP(pCodes);
      }
      return pCodes;
    }));
  }


  const getDropdown = (item) => {
    // Styling for the container
    const containerStyle = {
      display: 'flex',
      alignItems: 'center', // Align items vertically center
      marginBottom: '1rem', // Space below the dropdown
    };
  
    // Styling for the label
    const labelStyle = {
      marginRight: '10px', // Space between the label and the dropdown
      fontWeight: 'bold', // Optional: making label bold
      alignItems:'top',
      marginBottom:'10px'
    };
    
    return (
        <div className="procedure-codes-dropdown w-100">
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
              item={item}
              onChange={item.onChange}
            />
          )}
        />
      </div>
    );
  }
  

  //Display the apply tp button Before the global search
  const beforeGlobalSearchButton = () => {
    
    const onChange = (value, e) => {
      if (dataTableRef?.current && value) {
        dataTableRef.current.setFilterCriteria(filterCriteria => {
          const criteria = filterCriteria?.criteria ? filterCriteria.criteria : [];
          const i = criteria.findIndex(element => element.key === 'category');
    
          if (value === undefined) {
            // Clear the filter for 'category'
            if (i >= 0) {
              criteria.splice(i, 1); // Remove the category filter criteria
            }
          } else {
            // Update or add new filter criteria
            if (i >= 0) {
              criteria[i].value = value;
            } else {
              criteria.push({ key: 'category', value, type: 'regexOr' });
            }
          }
    
          filterCriteria.criteria = criteria;
          dataTableRef.current?.getDataFromServer(filterCriteria);
          return filterCriteria;
        });
      }
    }
    
    
    const item = {
      name: 'category',
      options: config.categoryOptions,
      disableLabel: true,
      labelClass: ' ',
      placeholder: 'Select a category',
      onChange: onChange,
      label:'Category',
      showClear:true
    };
    const labelStyle = {
      marginRight: '10px', // Space between the label and the dropdown
      fontWeight: 'bold', // Optional: making label bold
      alignItems:'top',
      marginBottom:'10px'
    };
    

    return <>
      <div className="col-7 d-flex justify-content-end align-items-center">
      <label htmlFor={item.name} style={labelStyle}>{item.label}</label>
        <div style={{width:'40%'}}>{getDropdown(item)}</div>
      </div>
    </>
  }

  //Display the apply tp button After the global search
  const proceduresModalFooter = () => {
    if (addProcedureType === 'procedureCodes') {
      return <div className="col-1" style={{marginTop:"3px"}}>
        <Button className="px-2" color="primary" onClick={onClickDataTableApplyTP}>Apply TP</Button>
      </div>;
    }
    return;

  }

  /**
   * 
   * @returns 
   */
  const getTPDropDown = (type) => {//Display the Dropdown with the Apply Tp Button

    return <div className="mt-4">

      <DataTable
        onRef={ref => dataTableRef.current = ref}
        getTableFields={getTableFields}
        formFields={() => []}
        tableFields={getTableFields}
        defaultFilterCriteria={{ limit: 50, page: 1, criteria: [], sortfield: 'created', direction: 'desc' }}
        type={"Procedures"}
        displayViewOfForm="screen"
        disableRefresh={true}
        showFilterOptions={true}
        disableHeader={true}
        isDisablePaginator={true}
        disableClearFilter={true}
        disableSaveFilter={true}
        disableActionDropdown={true}
        apiResponseKey={apiCalls.procedures.toLowerCase()}
        apiUrl={apiCalls.procedures}
        globalSearch={"Code/Description"}
        beforeGlobalSearchButton={beforeGlobalSearchButton}
        afterGlobalSearchButton={proceduresModalFooter}

      />

    </div >
  }

  /**
   * 
   * @param {Object} obj1 
   * @param {Object} obj2 
   * @returns 
   */
  function areObjectsNotEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if they have the same number of keys
    if (keys1.length !== keys2.length) {
      return true;
    }

    return keys1.some(key => obj1[key] !== obj2[key]);
  }

  async function saveChartHistory(teethsData) {
    const chartHistoryId = await props.getValues('treatmentPlanHeading.chartHistoryId');
    let saveHistory = teethHistorys?.length === 0;
    if (chartHistoryId && teethsData) {
      const hideTeeth = getHideTeeths();
      if (!saveHistory && teethHistorys?.length >= 1) {
        let arr = teethHistorys[teethHistorys?.length - 1]?.images ? teethHistorys[teethHistorys?.length - 1]?.images : [];
        const historyHideTeeth = teethHistorys[teethHistorys?.length - 1]?.hideTeeth ? teethHistorys[teethHistorys?.length - 1]?.hideTeeth : {};
        saveHistory = await areObjectsNotEqual(hideTeeth, historyHideTeeth) || await teethsData.some((teeth) => {
          const tee = arr.find(t => t.teethNumber == teeth.teethNumber && t.viewType == teeth.viewType);
          let l = tee?.procedureCodes?.length !== teeth?.procedureCodes?.length || tee?.show !== teeth?.show;
          return l;
        })
      }
      if (saveHistory) {
        const date = dateFormats.formatDate('UTCTimeNow');
        const api = apiCalls.chartHistories + `/${chartHistoryId}`;
        const data = { _id: chartHistoryId };
        data.teethHistoryImages = [{
          images: teethsData,
          hideTeeth,
          teethProcedureSteps: props.dataTableData ? props.dataTableData : [],
          date
        }]
        fetchMethodRequest('PUT', api, data).then((res) => {
          getTeethHistorys();
        });
      } else {
        // setTeethHistorys([]);
      }
    }
  }

  /**
   * 
   * @param {Object} item 
   * @returns 
   */
  const getAutoComplete = (item) => {
    return <Controller
      name={item.name}
      control={control}
      render={({ field, fieldState }) => (
        <DoAutoCompleteField
          input={field}
          id={field.id}
          name={field.name}
          field={field}
          item={item}
          fieldState={fieldState}
          errors={errors}
          searchApi={item.searchApi}
          searchField={item.searchField}
          placeholder={item.placeholder}
          label={item.label}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
        />)}
    />

  }

  /**
   * 
   * @param {Object} v 
   */
  const applyTxPlan = (v) => {
    if (selectedTeeths?.length > 0 && v?.procedureSteps?.length > 0) {
      const procedureCodes = v.procedureSteps.map(procedure => procedure.code);
      applyFillingToSelecetedTeeth('applyProcedure', procedureCodes);
      setValue('txPlan', undefined);
    }
  }

  /**
   * 
   * @param {Number} v 
   */
  const applyPriority = (v) => {
    if (props?.selectedRows?.length > 0) {
      props.handlePriorityChange(v);
      setValue('priority', undefined);
    }
  }


  const getDropdowns = () => {
    const txPlanItem = {
      name: 'txPlan',
      placeholder: 'Select a Tx plan',
      disableLabel: true,
      labelClass: ' ',
      optionLabel: 'heading',
      onChange: applyTxPlan,
      disabled: selectedTeeths?.length === 0,
      id: "txPlan",
      searchApi: apiCalls.txPlans,
      searchField: 'heading',
    };

    const priorityItem = {
      name: 'priority',
      placeholder: 'Select a priority',
      disableLabel: true,
      labelClass: ' ',
      onChange: applyPriority,
      options: config.priorityOptions,
      disabled: props?.selectedRows?.length === 0
    };

    return <>
      <div className="txplan_priority" style={{ width: '15%' }}>
        {getAutoComplete(txPlanItem)}
      </div>
      <div className="txplan_priority mx-3" style={{ width: '15%' }}>
        {getDropdown(priorityItem)}
      </div>
    </>
  }

  /**
   * 
   * @returns HTML
   */
  const getTabs = () => {//Display the Buttons 
    const disabled = selectedTeeths?.length === 0;
    const buttonsArr = [
      { header: 'Missing', onClick: () => applyToSelecetedTeeth('show', true), disabled },
      { header: 'Non-Missing', onClick: () => applyToSelecetedTeeth('show', false), disabled },
      { header: 'B/F', onClick: () => applyFillingToSelecetedTeeth('applyProcedure', ['D2391']), disabled },
      { header: 'V', onClick: () => applyFillingToSelecetedTeeth('applyProcedure', ['D2335']), disabled },
      { header: 'M', onClick: () => applyFillingToSelecetedTeeth('applyProcedure', ['D2391']), disabled },
      { header: 'O/I', onClick: () => applyFillingToSelecetedTeeth('applyProcedure', ['D2390']), disabled },
      { header: 'D', onClick: () => applyFillingToSelecetedTeeth('applyProcedure', ['D2391']), disabled },
      { header: 'L', onClick: () => applyFillingToSelecetedTeeth('applyProcedure', ['D2391']), disabled },
      { header: 'Procedure codes', onClick: () => setAddProceduresType('procedureCodes'), disabled },
    ];

    const onClick = (item) => {
      setSelecetedTeethHistory(-1);
      item.onClick();
    }
    return <div className="mt-2 d-flex flex-wrap">
      {buttonsArr.map(data => <><Button color="primary" disabled={data.disabled} onClick={() => onClick(data)}>{data.header}</Button>{data.break && <br />}</>)}
      {getDropdowns()}
      <Button color="primary" type="submit">
        Update
      </Button>
    </div>
  }

  const onClickHistoryDate = (item, i) => {

    setSelecetedTeethHistory(i);
    setTeethsData(item.images);
  };

  const timeLineCustomMarker = (item, i) => {

    const date = dateFormats.formatDate(item.date, config.dateFormat);
    const todayDate = dateFormats.formatDate('UTCTimeNow', config.dateFormat);

    return <div
      title={date}
      onClick={() => onClickHistoryDate(item, i)}
      className=""
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: '20px',
        color: (selectedTeethHistory == i || (selectedTeethHistory < 0 && date === todayDate)) ? 'blue' : 'black',// Highlight the clicked item
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <div>|</div>
        <div>{date === todayDate ? 'Today' : date.slice(0, 5)}</div>
      </div>
    </div>
  };

  const timeLineContent = (item) => {
    // const date = dateFormats.formatDate(item.date, config.dateFormat);
    // return <div title={date} onClick={() => onClickHistoryDate(item)}>{date.slice(0, 5)}</div>
  }

  /**
   * 
   * @returns chart History Bar
   */
  const getTimeLineBar = () => {
    const value = teethHistorys;

    return <div className="pb-2">
      <Timeline value={value} marker={timeLineCustomMarker} layout="horizontal" align="top" content={timeLineContent} />
    </div>
  };

  /**
   * 
   * @returns Html
   */
  function dispayTeethChart() {//Display the 32 Teeth with  the Number
    const topTeethF = [], topTeethT = [], bottomTeethF = [], bottomTeethT = [];

    const allToothData = teethsData.map((teeth) => {
      if (teeth.teethNumber <= 16) {
        if (teeth.viewType == 'T') {
          topTeethT.push(teeth);
        } else {
          topTeethF.push(teeth);
        }
      } else {
        if (teeth.viewType == 'T') {
          bottomTeethT.push(teeth);
        } else {
          bottomTeethF.push(teeth);
        }
      }
    });
    const hideTeeth = getHideTeeths(selectedTeethHistory);

    return <div className="display_teeth_header">
      {displayTeeth(topTeethF, hideTeeth, 'align-self-end')}
      {displayTeeth(topTeethT, hideTeeth)}
      {displayTeeth(topTeethT, hideTeeth, true)}
      <div style={{ borderBottom: '2px solid' }} className="my-1"></div>
      {displayTeeth(bottomTeethT, hideTeeth, true)}
      {displayTeeth(bottomTeethT, hideTeeth, 'align-self-end')}
      {displayTeeth(bottomTeethF, hideTeeth)}
      {getTimeLineBar()}
    </div>
  }

  // Loader in a Modal
  const renderLoaderDialog = (isLoading, setIsLoading) => {
    return (
      <Dialog
        headerStyle={{ display: 'none' }}
        visible={isLoading}
        style={{ width: '25vw' }}
        modal
        onHide={() => setIsLoading(false)}
        closeOnEscape={false}
      >
        <div className="d-flex flex-column align-items-center justify-content-center mt-4" style={{ height: '100%' }}>
          <div className="mb-4 fw-bold"> Please Wait... </div>
          <div className="mt-5 pt-2"> <Loader loader={isLoading} /></div>
        </div>
      </Dialog>
    );
  };

  const closeProceduresModal = () => {
    setAddProceduresType();
    setSelectedProcedureCodes([]);
    setValue('category', undefined);
  }

  const applyProceduresModal = () => {

    return <Dialog
      visible={['txPlan', 'procedureCodes'].includes(addProcedureType)}
      header={addProcedureType === 'procedureCodes' ? 'Add Procedure codes' : 'Procedure codes'}
      onHide={closeProceduresModal}
      style={{ width: "70%", height: '100%' }}
      draggable={false}
      // footer={proceduresModalFooter}
    >

      {getTPDropDown(addProcedureType)}
    </Dialog>
  }


  return <div className="charts" >
    {/* Loader */}
    <div>{renderLoaderDialog(isLoading, setIsLoading)}</div>
    {getTabs()}
    {teethsData && <div className="d-flex">

      {/* Display the Teeth */}
      {dispayTeethChart()}

      {/* Get the Tabs */}
      <div className="charts_tabs ">
        <div className="charts_tabs_inner">{children}</div>
      </div>


    </div>}

    {applyProceduresModal()}

  </div>
};

export default Charts;
